<template>
  <div
    class="bg-muted-100 dark:bg-muted-900 relative min-h-screen w-full overflow-hidden px-4"
  >
    <div class="flex w-full items-center justify-center">
      <div class="relative mx-auto w-full max-w-2xl">
        <div class="me-auto ms-auto mt-4 w-full">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
