<template>
  <UFormGroup :name="inputProps.name" :label="label">
    <UInput v-bind="inputProps" v-model="input" ref="uinput" />
  </UFormGroup>
</template>
<script setup lang="ts">
import type { UInput, UFormGroup } from "~/.nuxt/components";
import { MaskInput } from "maska";

const { label, inputProps, mask } = defineProps<{
  label: string;
  inputProps: InstanceType<typeof UInput>[`$props`];
  formGroupProps?: InstanceType<typeof UFormGroup>[`$props`];
  mask?: string[] | string;
}>();

const uinput = ref<InstanceType<typeof UInput>>();

const input = defineModel<string>({
  required: true,
});

let maskedInput: MaskInput | undefined;
onMounted(() => {
  if (mask && uinput.value?.input) {
    maskedInput = new MaskInput(uinput.value!.input!, {
      mask: mask,
    });
  }
});

onUnmounted(() => {
  maskedInput?.destroy();
});
</script>
